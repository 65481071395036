import { createClient } from '@iq/services';
import { getAccessToken } from '@iq/react-components';

import config from './config';

const services = createClient({
  ...config.services,
  onRequestToken: getAccessToken,
});

export function getServiceToken(type) {
  return services[type].getToken();
}

/** ********************************************
 *                                             *
 *               Auth                          *
 *                                             *
 ******************************************** */

export function getGrants(oid) {
  return services.auth.grants(`users/${oid}`);
}

export function getMemberTypes() {
  return services.auth.getMemberTypes();
}

export function createMember(data) {
  return services.auth.createMember(data);
}

export function updateMember(memberId, data) {
  return services.auth.updateMember(memberId, data);
}

export function deleteMember(memberId) {
  return services.auth.deleteMember(memberId);
}

export function getMembers(data) {
  return services.auth.getMembers(data);
}

export function deleteMemberConnection(memberId, userId) {
  return services.auth.deleteMemberConnection(memberId, userId);
}

export function getInvitations(query) {
  return services.auth.getInvitations(query);
}

export function createInvitation(data) {
  return services.auth.createInvitation(data);
}

export function updateInvitation(invitationId, data) {
  return services.auth.updateInvitation(invitationId, data);
}

export function deleteInvitation(invitationId) {
  return services.auth.deleteInvitation(invitationId);
}

export function authorizeUser(data) {
  return services.auth.authorizeUser(data);
}

export function getOneUser(userId) {
  return services.auth.getOneUser(userId);
}

export function getUsers(query) {
  return services.auth.getUsers(query);
}

export function getLimitedUsers(query) {
  return services.auth.getLimitedUsers(query);
}

export function deleteUser(userId) {
  return services.auth.deleteUser(userId);
}

export function getServiceAccounts() {
  return services.auth.getServiceAccounts();
}

export function transferServiceAccountPermissions(data) {
  return services.auth.transferServiceAccountPermissions(data);
}

export function deleteEntity(entity) {
  return services.auth.deleteEntity(entity);
}

/** ********************************************
 *                                             *
 *               Data                          *
 *                                             *
 ******************************************** */

export function checkConnectivity(sourceId) {
  return services.data.checkConnectivity(sourceId);
}

/** ********************************************
 *                                             *
 *               Site                          *
 *                                             *
 ******************************************** */

export function updateLayout(siteId, layoutId, layout) {
  return services.site.updateLayout(siteId, layoutId, layout);
}

/** ********************************************
 *                                             *
 *               Project                       *
 *                                             *
 ******************************************** */

export function getAllProjects() {
  return services.backoffice.getProjects();
}

export function fetchProject(id) {
  return services.backoffice.getOneProject(id);
}

export function createMimsProject(data) {
  return services.backoffice.createMimsProject(data);
}

export function createEcProject(data) {
  return services.backoffice.createEcProject(data);
}

export function createProject(data) {
  return services.backoffice.createProject(data);
}

export function updateProject(id, data) {
  return services.backoffice.updateProject(id, data);
}

export function deleteProject(id) {
  return services.backoffice.deleteProject(id);
}

export function exportMimsProject(id) {
  services.backoffice.exportMimsData(id);
}

export function exportEcProject(id) {
  services.backoffice.exportEcData(id);
}

export function downloadMimsConfig(id) {
  return services.backoffice.downloadMimsConfig(id);
}

export function downloadEcConfig(id) {
  return services.backoffice.downloadEcConfig(id);
}

export default services;
