.site-detail-view-component {
  padding-left: 4rem;
}

.no-side-margin {
  padding-left: 0 !important;
}

.base-view {
  padding-left: 4rem;
  padding-top: 1px;
}
.base-view .loading-container {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.base-view .panel-display-menu {
  display: flex;
  overflow: hidden;
}
.base-view .panel-display-menu a {
  float: left;
  display: block;
  text-align: center;
  padding: 1.5rem 2rem;
  text-decoration: none;
  font-size: medium;
  border-bottom: 3px solid transparent;
}
.base-view .mims-config {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1.5rem;
  gap: 10px;
}
.base-view .mims-config span {
  display: flex;
  width: 100%;
}
.base-view .mims-config dt {
  width: 180px;
}

button {
  font-size: 0.5rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvQmFzZVZpZXdJb1RDbG91ZCIsInNvdXJjZXMiOlsiQmFzZVZpZXdJb1RDbG91ZC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUdGO0VBQ0U7RUFDQTs7QUFHRjtFQUNFOzs7QUFJSjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiQHVzZSAnQGlxL3JlYWN0LWNvbXBvbmVudHMvZGlzdC9jb2xvcnMuc2NzcycgYXMgY29sb3JzO1xuXG4uc2l0ZS1kZXRhaWwtdmlldy1jb21wb25lbnQge1xuICBwYWRkaW5nLWxlZnQ6IDRyZW07XG59XG5cbi5uby1zaWRlLW1hcmdpbiB7XG4gIHBhZGRpbmctbGVmdDogMCAhaW1wb3J0YW50O1xufVxuXG4uYmFzZS12aWV3IHtcbiAgcGFkZGluZy1sZWZ0OiA0cmVtO1xuICBwYWRkaW5nLXRvcDogMXB4O1xuXG4gIC5sb2FkaW5nLWNvbnRhaW5lciB7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgaGVpZ2h0OiA5MHZoO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgfVxuXG4gIC5wYW5lbC1kaXNwbGF5LW1lbnUge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgb3ZlcmZsb3c6IGhpZGRlbjtcbiAgfVxuXG4gIC5wYW5lbC1kaXNwbGF5LW1lbnUgYSB7XG4gICAgZmxvYXQ6IGxlZnQ7XG4gICAgZGlzcGxheTogYmxvY2s7XG4gICAgdGV4dC1hbGlnbjogY2VudGVyO1xuICAgIHBhZGRpbmc6IDEuNXJlbSAycmVtO1xuICAgIHRleHQtZGVjb3JhdGlvbjogbm9uZTtcbiAgICBmb250LXNpemU6IG1lZGl1bTtcbiAgICBib3JkZXItYm90dG9tOiAzcHggc29saWQgdHJhbnNwYXJlbnQ7XG4gIH1cblxuICAubWltcy1jb25maWcge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgICBmbGV4LXdyYXA6IHdyYXA7XG4gICAgcGFkZGluZzogMS41cmVtO1xuICAgIGdhcDogMTBweDtcbiAgfVxuXG4gIC5taW1zLWNvbmZpZyBzcGFuIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIHdpZHRoOiAxMDAlO1xuICB9XG5cbiAgLm1pbXMtY29uZmlnIGR0IHtcbiAgICB3aWR0aDogMTgwcHg7XG4gIH1cbn1cblxuYnV0dG9uIHtcbiAgZm9udC1zaXplOiAwLjVyZW07XG59XG4iXX0= */