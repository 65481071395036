import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './reducers';
import watchAll from './sagas';
import { actionObserveMiddleware } from './utils';

const setupStore = (preloadedState = {}) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    reducer,
    preloadedState,
    composeEnhancers(applyMiddleware(sagaMiddleware, actionObserveMiddleware))
  );

  sagaMiddleware.run(watchAll);
  return store;
};

export default setupStore;
