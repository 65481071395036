import React, { useMemo, useState, useRef, useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { Icon, Button } from '@iq/react-components';

import { TYPES } from '../../../bundles/notifications';
import Notification from '../Notification';

const NotificationsPanel = ({ notifications, onClear, onClearAll, onClose }) => {
  const [expandedIds, setExpandedIds] = useState([]);

  const notificationRef = useRef(null);

  function handleOutsideClick(e) {
    if (!notificationRef.current?.contains(e.target)) {
      onClose(e);
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, true);
    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
    };
  }, []);

  const persistent = useMemo(
    () => notifications.filter((n) => n.type === TYPES.persistent),
    [notifications]
  );
  const actionable = useMemo(
    () => notifications.filter((n) => n.type === TYPES.actionable),
    [notifications]
  );

  const handleExpandNotificationGroup = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    if (notifications.find((n) => n.id === id && n.items.length > 1)) {
      if (expandedIds.includes(id)) {
        setExpandedIds(() => expandedIds.filter((i) => i !== id));
      } else {
        setExpandedIds(() => [...expandedIds, id]);
      }
    }
  };

  const buildNotifications = (notificationSubset) =>
    notificationSubset.map((notification, i) => {
      if (expandedIds.includes(notification.id)) {
        return (
          <div
            className="notifications-panel--body-expanded"
            key={`${i}${notification.createdAt}`}
          >
            <div className="collapser">
              <Button
                className="clear"
                activity="secondary"
                design="outlined"
                slim
                onClick={(e) => handleExpandNotificationGroup(e, notification.id)}
              >
                Collapse
              </Button>
            </div>
            {notification.items.map((item, j) => (
              <Notification
                key={`${notification.id}-${j}`}
                notification={item}
                onClear={onClear}
              />
            ))}
          </div>
        );
      }
      return (
        <Notification
          key={`${i}${notification.createdAt}`}
          notification={notification}
          onClear={onClear}
          onExpand={handleExpandNotificationGroup}
          onClick={(e) => handleExpandNotificationGroup(e, notification.id)}
          stacked={notification.items.length > 1}
        />
      );
    });

  return (
    <div
      className="notifications-panel"
      ref={notificationRef}
    >
      <div className="notifications-panel--header">
        <Button
          activity="secondary"
          slim
          onClick={onClearAll}
        >
          Dismiss all
        </Button>
        <Icon
          size="xs"
          icon="he-close"
          onClick={onClose}
        />
      </div>
      <div className="notifications-panel--body custom-thin-scrollbar">
        {notifications.length === 0 ? (
          <div className="notifications-panel--body-empty">No notifications</div>
        ) : (
          [buildNotifications(persistent), buildNotifications(actionable)]
        )}
      </div>
    </div>
  );
};

export default NotificationsPanel;
