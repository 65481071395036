import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Icon, Button } from '@iq/react-components';
import { RefreshSharp as RefreshIcon } from '@mui/icons-material';
import { refreshData, getPollingActive } from '../../../bundles/application';
import { getActiveUiMode } from '../../../bundles/sites';

const PollingRefresh = ({ siteId }) => {
  const dispatch = useDispatch();
  const isPolling = useSelector(getPollingActive);
  const uiMode = useSelector(getActiveUiMode);

  const spinningClass = classnames({ polling: isPolling });

  return (
    <div className="polling-refresh">
      <Button
        activity="secondary"
        className={`refresh-variables-button ${uiMode}`}
        onClick={() => dispatch(refreshData(siteId))}
        icon={
          uiMode === 'Lumada' ? (
            <RefreshIcon sx={{ fontSize: '1.6rem' }} />
          ) : (
            <Icon
              icon="he-load"
              size="s"
              className={spinningClass}
            />
          )
        }
        disabled={isPolling}
        tooltip="Refresh now"
      />
    </div>
  );
};

export default PollingRefresh;
