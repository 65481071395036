import { all } from 'redux-saga/effects';

import { sagas as ad } from './bundles/ad';
import { sagas as application } from './bundles/application';
import { sagas as auth } from './bundles/auth';
import { sagas as components } from './bundles/components';
import { sagas as layouts } from './bundles/layouts';
import { sagas as notifications } from './bundles/notifications';
import { sagas as sites } from './bundles/sites';
import { sagas as sources } from './bundles/sources';
import { sagas as variables } from './bundles/variables';
import { sagas as projects } from './bundles/projects';

function* watchAll() {
  yield all([
    ...ad,
    ...application,
    ...auth,
    ...components,
    ...layouts,
    ...notifications,
    ...sites,
    ...sources,
    ...variables,
    ...projects,
  ]);
}

export default watchAll;
