import { PROJECT_DELIVERY_TYPE } from '../../../../../constants';

const localhost = window.location.hostname === 'localhost';

const getProjectSchema = () => {
  return {
    type: 'object',
    required: ['name', 'deliveryType', 'ip', 'subscriptionType'],
    properties: {
      name: {
        type: 'string',
        title: 'Project Name',
        minLength: 5,
        placeholder: 'Name of the Project',
        default: localhost ? 'Test Project' : '',
      },
      deliveryType: {
        default: PROJECT_DELIVERY_TYPE.MIMS,
        type: 'string',
        title: 'Delivery Type',
        enum: [PROJECT_DELIVERY_TYPE.MIMS, PROJECT_DELIVERY_TYPE.EC],
      },
      ip: {
        type: 'string',
        format: 'ipv4',
        title: 'MIMS IP',
        placeholder: '123.456.7.89',
        default: localhost ? '123.45.67.89' : '',
      },
      subscriptionType: {
        default: 'ON_CHANGE',
        type: 'string',
        title: 'Subscription Type',
        enum: ['ON_CHANGE', 'SAMPLE_INTERVAL'],
      },
      sampleRate: {
        type: 'number',
        title: 'Sample Rate (ms)',
        placeholder: 'Must be between 1000 and 65534 ms',
      },
    },

    dependencies: {
      subscriptionType: {
        oneOf: [
          {
            properties: {
              subscriptionType: {
                enum: ['ON_CHANGE'],
              },
              sampleRate: {
                type: 'number',
                title: 'Sample Rate (ms)',
                placeholder: 'Must be between 1000 and 65534 ms',
                readonly: true,
              },
            },
          },
          {
            properties: {
              subscriptionType: {
                enum: ['SAMPLE_INTERVAL'],
              },
              sampleRate: {
                type: 'number',
                title: 'Sample Rate (ms)',
                placeholder: 'Must be between 1000 and 65534 ms',
                minimum: 1000,
                maximum: 65534,
              },
            },
          },
        ],
      },
    },

    if: {
      properties: {
        deliveryType: {
          const: 'MIMS + Energy Connect',
        },
      },
    },
    then: {
      required: [
        'moduleId',
        'environment',
        'tenantId',
        'edgeId',
        'projectId',
        'typeDefAPI',
        'dataAccessAPI',
        'deviceTwinAPI',
      ],
      properties: {
        moduleId: {
          type: 'string',
          title: 'MIMS Module ID',
          placeholder: '00000000-0000-0000-0000-000000000000',
          format: 'uuid',
          default: localhost ? '8a196eef-f3dd-4576-b27a-6cd4b8f1eef2' : '',
        },
        environment: {
          type: 'string',
          title: 'EC Environment',
          placeholder: 'Name of the Environment',
          default: localhost ? 'test' : '',
        },
        tenantId: {
          type: 'string',
          title: 'Tenant ID',
          placeholder: '00000000-0000-0000-0000-000000000000',
          format: 'uuid',
          default: localhost ? '8a196eef-f3dd-4576-b27a-6cd4b8f1eef2' : '',
        },
        edgeId: {
          type: 'string',
          title: 'Edge ID',
          placeholder: '00000000-0000-0000-0000-000000000000',
          format: 'uuid',
          default: localhost ? '8a196eef-f3dd-4576-b27a-6cd4b8f1eef2' : '',
        },
        projectId: {
          type: 'string',
          title: 'Project ID',
          placeholder: '00000000-0000-0000-0000-000000000000',
          format: 'uuid',
          default: localhost ? '8a196eef-f3dd-4576-b27a-6cd4b8f1eef2' : '',
        },
        typeDefAPI: {
          type: 'string',
          title: 'EC Type Definition API',
          pattern:
            '[A-Za-z]+://[A-Za-z]+.[A-Za-z]+.[A-Za-z]+.[A-Za-z]+.([A-Za-z]+(/[A-Za-z]+)+).[A-Za-z]+',
          default: localhost
            ? 'http://localhost/mach/b6a9557a-412d-41c6-844d-7d18cc429136.json'
            : '',
        },
        dataAccessAPI: {
          type: 'string',
          title: 'EC Data Access API',
          pattern:
            '[A-Za-z]+://[A-Za-z]+.[A-Za-z]+.[A-Za-z]+.[A-Za-z]+.([A-Za-z]+(/[A-Za-z]+)+).[A-Za-z]+',
          default: localhost
            ? 'http://localhost/mach/b6a9557a-412d-41c6-844d-7d18cc429136.json'
            : '',
        },
        deviceTwinAPI: {
          type: 'string',
          title: 'EC Device Twin API',
          pattern:
            '[A-Za-z]+://[A-Za-z]+.[A-Za-z]+.[A-Za-z]+.[A-Za-z]+.([A-Za-z]+(/[A-Za-z]+)+).[A-Za-z]+',
          default: localhost
            ? 'http://localhost/mach/b6a9557a-412d-41c6-844d-7d18cc429136.json'
            : '',
        },
      },
    },
  };
};

const projectUISchema = {
  'ui:options': { orderable: false },
  subscriptionType: {
    'ui:enumNames': ['On Change', 'Sample Interval'],
  },
};

export { getProjectSchema, projectUISchema };
