import React, { useState, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DropdownMenu } from '../DropdownMenu';
import {
  getProjects,
  deleteProject,
  createMimsProject,
  createEcProject,
} from '../../bundles/projects';
import AppContext from '../../AppContext';
import ConfirmationDialog from '../ConfirmationDialog';
import Notifications from '../Notifications';
import PageHeader from '../PageHeader';
import SiteThumbnail from '../SiteThumbnail';
import identiqBlack from '../../../images/identiq-black.svg';
import identiqWhite from '../../../images/identiq-white.svg';

import ProjectCreateUpdateView from '../ProjectCreateUpdateView';
import { PROJECT_DELIVERY_TYPE } from '../../constants';

const ProjectListView = ({ realm }) => {
  const { theme } = useContext(AppContext);
  const projects = useSelector(getProjects);
  const [projectToDelete, setProjectToDelete] = useState(false);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('');

  const onDeleteProject = (name, id) => {
    setProjectToDelete({ name, id });
  };

  const itemMenuOptions = (projectName, projectId) => [
    {
      component: <>Delete project</>,
      onSelect: () => onDeleteProject(projectName, projectId),
    },
  ];

  const filteredProjects = useMemo(() => {
    if (projects && projects.length > 0) {
      return projects.filter(({ name = {} }) => {
        if (name) {
          const needles = [name].map((s) => s.toLowerCase());
          return needles.some((needle) => needle.includes(filter.toLowerCase()));
        }
        return false;
      });
    }
    return [];
  }, [projects, filter]);

  const handleProjectCreation = (project) => {
    if (project.deliveryType === PROJECT_DELIVERY_TYPE.EC) {
      dispatch(createEcProject(project));
    } else {
      dispatch(createMimsProject(project));
    }
  };

  return (
    <>
      <Notifications portalOnly />
      <PageHeader
        title={{
          type: 'image',
          value: theme === 'dark' ? identiqWhite : identiqBlack,
        }}
        realm={realm}
        sitesList
        setFilter={setFilter}
      >
        <Notifications />
      </PageHeader>
      <div className="site-list-view-component">
        <div className={`sidebar ${theme}-theme`}>
          <div className="site-list custom-scrollbar">
            <div className="projects-projects">
              Projects
              <div className="project-btn-component">
                <ProjectCreateUpdateView
                  title="Create Project"
                  onSave={handleProjectCreation}
                />
              </div>
            </div>
            {filteredProjects.map((project) => (
              <Link
                className="site site-list-link"
                key={project.name}
                to={`/mach/${project.id}/`}
              >
                <div className="thumbnail">
                  <SiteThumbnail name={project.name} />
                </div>
                <div className="info">
                  <div className="name">{project.name}</div>
                  <div className="projectType">{project.projectType}</div>
                </div>
                <div className="drop-down-menu">
                  <DropdownMenu
                    trigger="he-moreoptionshorizontal"
                    menuOptions={itemMenuOptions(project.name, project.id)}
                    className="site-item-menu"
                    menuXPlacement="left"
                  />
                </div>
              </Link>
            ))}
            {!filteredProjects.length && <div className="no-projects">No projects to display</div>}
          </div>
        </div>
        {projectToDelete && (
          <ConfirmationDialog
            onCancel={() => setProjectToDelete(null)}
            onConfirm={() => {
              setProjectToDelete(null);
              dispatch(deleteProject(projectToDelete.id, projectToDelete.name));
            }}
            confirmType="danger"
            title="Delete project"
            body={
              <>
                Are you sure you want to delete project <b>{projectToDelete.name}</b>?
              </>
            }
            confirmText="Yes, delete"
            showCancel={true}
            cancelText="No, cancel"
          />
        )}
      </div>
    </>
  );
};

export default ProjectListView;
